<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-back" @click="cancel(1)">返回</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" 
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}" 
    row-key="id" stripe
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
       <el-table-column label="状态" align="center" show-overflow-tooltip width="80">
        <template slot-scope="scope">
          <span >{{getOrderStatusStr(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="年份/学期" align="center" show-overflow-tooltip width="90">
        <template slot-scope="scope">
          <span >{{scope.row.year}}年<br />
          {{scope.row.semester==0?'春季学期':'秋季学期'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="地区" align="left" show-overflow-tooltip width="180">
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName}}</span><br />
          <span >{{scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="学校" align="left" show-overflow-tooltip width="160">
        <template slot-scope="scope">
          <span >{{scope.row.schoolName}}</span><br />
          <span v-if="scope.row.orderType==1" >{{scope.row.gradeName+','+scope.row.className}}</span>
        </template>
      </el-table-column>
     
      <el-table-column label="姓名" align="center" show-overflow-tooltip width="100" >
        <template slot-scope="scope">
          <span >{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" align="center" show-overflow-tooltip width="120">
        <template slot-scope="scope">
          <span >{{scope.row.phone}}</span>
         </template>
      </el-table-column>   
       <el-table-column label="批次" prop="createTime" align="center" width="60">
        <template slot-scope="scope">
         <span >{{scope.row.batch}}</span>
        </template>
      </el-table-column> 
      <el-table-column label="订单号" align="left"  >
        <template slot-scope="scope">
          <span >{{'订单号：'+scope.row.orderCode}}</span><br />
          <span >{{'微：'+scope.row.paymentStream}}</span>
        </template>
      </el-table-column> 
      <el-table-column label="支付信息" align="left"  >
        <template slot-scope="scope">
          <span >{{'时间：'+scope.row.paymentTime}}</span><br />
          <span >{{'金额：'+(scope.row.totalMoney || '-')}}</span>
        </template>
      </el-table-column> 
      <el-table-column label="退款信息" align="left" show-overflow-tooltip >
        <template slot-scope="scope">
          <span >{{'时间：'+ (scope.row.refundTime || '-') }}</span><br />
          <span >{{'金额：'+(scope.row.refundMoney || '-') }}</span>
        </template>
      </el-table-column>
     
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>



</template>

<script>
import { getRefundRecordInfoList } from '@/api/index.js'
export default {
  name: 'CustomerForm',
  components: {  },
  props: ['editId'],
  data() {
    return {
      form: {
        refundBatchId:'',
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
      },
      list: [],
      total: 0,
    }
  },
  created() {
      this.form.refundBatchId = this.editId;
      this.getRefundRecordInfoList();
  },
  mounted() {
    
  },
  methods: {
    //返回
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
     getOrderStatusStr(statusCode){
      let statusStr = '';
      switch(statusCode){
        case '0':
          statusStr = '待支付';
          break;
        case '1':
          statusStr = '支付中';
          break;
        case '2':
          statusStr = '已支付';
          break;
        case '3':
          statusStr = '全额退款';
          break;
        case '4':
          statusStr = '部分退款';
          break;
        case '5':
          statusStr = '已关闭';
          break;
        case '5':
          statusStr = '退款中';
          break;
      }
      return statusStr;
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getRefundRecordInfoList()
    },
    //编辑获取详情
    getRefundRecordInfoList(){ 
      getRefundRecordInfoList(this.form).then(res => {
        if (res.code != 200) return
          this.list = res.data.records
        this.total = res.data.total       
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss';

/**编辑套餐信息中的组合列表样式 */
.package-box{
  width:840px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.package-box .box-left{
  flex: 0 0 690px;
  border-right: 1px solid #C9E3FB;
}
.package-box .box-left .package-info{
  display: flex;
  border-bottom: 1px solid #C9E3FB;
  line-height: 70px;
  height: 70px;
}
.package-box .box-left .package-info .package-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  padding-left: 5px;
}
.package-box .box-left .package-info .item{
  flex: 1;
  text-align: left;
  line-height: 70px;
  padding-left:10px;
}
.package-box .box-left .combination-box{
  padding-left:50px;
}
.package-box .box-left .combination-box .combination-info{
  display: flex;
  border-left: 1px solid #C9E3FB;
  line-height: 60px;
  height: 60px;
}

.package-box .box-left .combination-box .combination-info:not(:last-child){
  border-bottom: 1px solid #C9E3FB;
}


.package-box .box-left .combination-box .combination-info .combination-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  padding-left: 5px;
}
.package-box .box-left .combination-box .combination-info .item{
  flex: 1;
  text-align: left;
  line-height: 60px;
  padding-left:10px;
}

.package-box .box-price{
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #C9E3FB;
}

.package-box .box-price .price{
  margin: auto;
  text-align: center;
  padding-top:5px;
}

.package-box .box-price .price .new-price{
  margin: auto;
  text-align: center;
  padding-top:5px;
  font-size:16px;
  color:red;
}

.package-box .box-price .price .old-price{
  text-decoration:line-through;
}


.package-box .box-right{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.package-box .box-right .btn{
  margin: auto;
  text-align: center;
  padding-top:5px;
}


</style>
