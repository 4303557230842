<template>
  <div style="width:100%">
    <RefundRecordList v-if="editType == 'list'"  @changeShowType="changeShowType"></RefundRecordList>
    <RefundRecordInfo v-if="editType == 'show'" :editId="editId"    @changeShowType="changeShowType"></RefundRecordInfo>
  </div>
</template>

<script>
import RefundRecordList from './components/RefundRecordList'
import RefundRecordInfo from './components/RefundRecordInfo'

export default {
  name: 'refundRecord',
  components: { RefundRecordList,RefundRecordInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>