<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
          <el-form-item label="退款时间">
          <el-date-picker v-model="paymentTimeRang"  type="daterange" align="right" style="width:220px;"
             unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" >
          </el-date-picker>
        </el-form-item>

          <el-form-item label="退款人">
          <el-input v-model="form.search" placeholder="退款人"></el-input>
          </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" 
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}" 
    row-key="id" stripe
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="退款人" align="center" show-overflow-tooltip prop="operateUserName"></el-table-column>
      <el-table-column label="退款时间" align="center" show-overflow-tooltip prop="createTime"></el-table-column>
      <el-table-column label="退款人数" align="center" show-overflow-tooltip prop="totalCount"></el-table-column> 
      <el-table-column label="成功人数" align="center" show-overflow-tooltip prop="successCount" ></el-table-column> 
      <el-table-column label="成功金额" align="center" show-overflow-tooltip prop="successRefundMoney" ></el-table-column> 
      <el-table-column label="失败人数" align="center" show-overflow-tooltip prop="totalCount"></el-table-column>

     
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail(scope.row.id,'show')">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>
</template>

<script>
import ChooseArea from '@/components/ChooseArea/index.vue'
import {  getRefundRecordList } from '@/api/index.js'
export default {
  name: 'capital',
  components: {ChooseArea},
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:''
      },
      list: [],
      total: 0,
      editType: '',
      paymentTimeRang:[],
    }
    
  },
  created() {

    
    this.getList();
  },
  methods: {
    editDetail(id, editType) {
      this.$emit('changeShowType', editType,id)
    },
    getList() {
      this.form.startTime = '';
      this.form.endTime ='';
      if(this.paymentTimeRang.length>0){
        this.form.startTime = this.paymentTimeRang[0];
      }
      if(this.paymentTimeRang.length>1){
        this.form.endTime = this.paymentTimeRang[1];
      }
      getRefundRecordList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
</style>